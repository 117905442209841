import * as Icon from 'react-feather';

function Contact() {

    return (
        <section id="contact">
            <h2>Contato</h2>
        </section>
    );
}

export default Contact;